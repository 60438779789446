<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refListTable'].refresh()"
      @onSelectChange="changeTypePayment"
    >
      <template #buttons>
        <!-- Button Sms -->
        <!-- <b-button
          variant="success"
          class="ml-1"
          :disabled="lengthClients"
          @click="openModalSendSms"
        >
          <feather-icon icon="MessageCircleIcon" class="mr-50" />Send SMS
        </b-button> -->
        <!-- Button Send Email -->
        <b-button
          variant="success"
          class="ml-1"
          :disabled="lengthClients"
          @click="openModalSendEmail"
        >
          <feather-icon icon="MailIcon" class="mr-50" />Send Email
        </b-button>
      </template>
      <b-table
        slot="table"
        ref="refListTable"
        responsive="sm"
        table-class="text-nowrap"
        sticky-header="68vh"
        show-empty
        empty-text="No matching records found"
        small
        striped
        hover
        :fields="fields"
        :items="myProvider"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #head(selected)>
          <b-form-checkbox v-model="selectAll" @input="selectedAll" />
        </template>
        <template #cell(selected)="data">
          <b-form-group>
            <b-form-checkbox
              v-model="clientsSms"
              :value="{
                id: data.item.account.account_id,
                name: data.item.lead_name,
              }"
              :name="data.item.account.account_id"
              class="mt-1"
            />
          </b-form-group>
        </template>
        <template #cell(lead_name)="data">
          <div
            style="font-weight: 600"
            class="clickable"
            @click="openSidebar(data.item.lead_id)"
            :class="isDarkSkin ? 'text-warning' : 'text-primary'"
          >
            {{ data.item.lead_name }}
          </div>
        </template>
        <template #cell(account)="data">
          <router-link
            target="_blank"
            :to="{
              name: 'specialists-client-dashboard',
              params: { idClient: `${data.item.account.account_id}` },
            }"
          >
            {{ data.item.account.id }}
          </router-link>
        </template>
        <template #cell(status)="data">
          <status-account :account="data.item.account" />
        </template>
        <template #cell(charges)="data">
          $ {{ data.item.account.charges }}
        </template>
        <template #cell(monthly_amount)="data">
          $ {{ data.item.account.monthly_amount }}
        </template>
        <template #cell(last_payment)="data">
          <div style="position: relative">
            <span style="margin-left: 15px">
              {{ data.item.account.last_payment || "-" }}
              <img
                :src="
                  assetsImg +
                  '/images/icons/lastpayment' +
                  (data.item.account.state_lp == 1 ? '-out.ico' : '-in.ico')
                "
                style="
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translate(0, -50%);
                "
              />
            </span>
          </div>
        </template>
        <template #cell(pt)="data">
          <status-payment :account="data.item.account" />
        </template>
        <template #cell(advisor_name)="data">
          {{ data.item.account.advisor_name }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.account.created_at | myGlobalDay }}
        </template>
        <template #cell(services)="data">
          <actions-table
            :options="serviceOptions"
            :row-data="data.item"
            @modalChangeService="openAddService(4, data.item)"
            @modalAddService="openAddService(3, data.item)"
          />
        </template>
        <template #cell(actions)="data">
          <actions-table
            :options="actionOptions"
            :row-data="data.item"
            @modalSendEmailOpen="
              modalSendEmailOpen(
                data.item.account.account_id,
                data.item.lead_name
              )
            "
            @modalSmsOpen="
              modalSmsOpen(data.item.account.account_id, data.item.lead_name)
            "
          />
        </template>
      </b-table>
    </filter-slot>
    <!-- Modal send sms -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="clientsSms[0]"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Send Email -->
    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="clientsSms"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />
    <!-- Component Commons -->
    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :type-modal="modalData.programs.typeModal"
      :sales-client="saleClientP"
      :programs-all="programsAll"
      :users-services="usersServices"
      :header-s="headerS"
      @closeModal="hideModalProgram"
      @changeProgram="openModalProgram"
    />
    <!-- Sidebar -->
    <sidebar-edit-lead
      v-if="editSidebar"
      :lead_id="lead_id"
      :moduleMeta="11"
      @refresh="closeSidebar"
      @close="editSidebar = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FilterClients from "@/views/specialists/views/clients/extras/client.filter.js";
import FieldsClients from "@/views/specialists/views/clients/extras/client.fields.js";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import ActionsTable from "@/views/crm/views/Lead/lead-table/ActionsTable.vue";
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import ModalAddService from "@/views/commons/components/clients/modals/ModalAddService.vue";
import ModalCallRounds from "@/views/boost-credit/views/clients/components/clients/modals/SendToCallRounds.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import SidebarEditLead from "@/views/specialists/views/clients/components/SidebarEditLead.vue";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import ClientSpecialistService from "@/views/specialists/views/clients/services/clients.service.js";
import GlobalService from "@/service/global/index";
export default {
  components: {
    SidebarEditLead,
    FilterSlot,
    StatusAccount,
    StatusPayment,
    ActionsTable,
    BusinessModal,
    CreditExpertsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    ModalAddService,
    ModalCallRounds,
    ModalSendEmail,
    ModalSendSms,
  },
  data() {
    return {
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      filters: FilterClients,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      perPageOptions: [10, 25, 50, 100],
      sortBy: "",
      totalRows: 0,
      toPage: null,
      startPage: null,
      isBusy: false,
      sortDesc: true,
      fields: FieldsClients,
      serviceOptions: [],
      actionOptions: [],
      event: null,
      modalOpenAddService: false,
      modalSendSms: false,
      modalSendEmail: false,
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      modal: {
        programs: false,
      },
      detailsClient: {},
      editSidebar: false,
      lead_id: null,
      clients: [],
      allClients: [],
      clientsSms: [],
      selectAll: 0,
      programsAll: [],
      usersServices: [],
    };
  },
  mounted() {
    let options = ["addService", "changeService"];
    let services = ["sendToCallReturns", "sendSMS", "sendEmail"];
    this.serviceOptions.push(...options);
    this.actionOptions.push(...services);
    this.getUsersData();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    lengthClients() {
      return this.clientsSms.length == 0;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    changeTypePayment(type) {
      if (type == "paymentType") {
        if (this.filters[4].model == 1) {
          this.filters[5].visible = true;
        } else {
          this.filters[5].model = null;
          this.filters[5].visible = false;
        }
      }
    },
    async getUsersData() {
      const data = await GlobalService.getSellers(11, {
        roles: "[]",
        type: "1",
      });
      if (data.status === 200) {
        this.filters[2].options = data.data.map((item) => {
          return {
            id: item.id,
            label: item.user_name,
          };
        });
        this.filters[2].options.unshift({
          id: null,
          label: "All",
        });
      }
    },
    async myProvider(ctx) {
      try {
        const params = {
          text: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          day: this.filters[5]?.model,
          advisor: this.filters[2].model,
          page: this.paginate.currentPage,
          perpage: this.paginate.perPage,
          orderby: 5,
          type: this.filters[4].model,
          typeaccount: 2,
          order: "desc",
          user: this.currentUser.user_id,
          program: null,
          status: this.filters[3].model,
          typeSp: "OLD",
        };

        const { data, total, from, to, current_page, per_page } =
          await ClientSpecialistService.searchClientSpOld(params);
        this.totalRows = total;
        this.startPage = from;
        this.toPage = to;
        this.paginate.currentPage = current_page;
        this.paginate.perPage = per_page;
        const items = data.map((item) => {
          return {
            account: JSON.parse(item.accounts)[0],
            ...item,
          };
        });
        this.clients = items;
        return items || [];
      } catch (err) {
        console.error("Error trying to get all the entrances");
        throw err;
      }
    },
    async openAddService(typeModal, client) {
      const result = await this.showConfirmSwal(
        typeModal === 3
          ? "Are sure want to add new service?"
          : "Are sure want to change service?"
      );
      if (result.value) {
        let data = {
          lead_name: client.lead_name,
          lead_id: client.lead_id,
          program: client.account.program,
          account_id: client.account.account_id,
        };
        await this.createEvent(data);
        this.modalGeneralService(data, typeModal);
      }
    },
    async modalSendSMS(data) {
      const result = await this.showConfirmSwal("Are sure want to send SMS?");
      if (result.value) {
        this.modalGeneralService(data, 2);
      }
    },
    async modalSendEmailMenu(data) {
      const result = await this.showConfirmSwal("Are sure want to send Email?");
      if (result.value) {
        this.modalGeneralService(data, 2);
      }
    },
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    async openModalProgram(data) {
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: this.detailsClient.account_id,
            captured: data.captured.id,
            seller: data.seller.id,
            type: 1,
            user_id: this.currentUser.user_id,
            module: this.moduleId,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            const result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              this.$router
                .push({
                  name: "sales-made-specialists-add-change",
                  // params: {
                  //   typeService: data.typeService,
                  // },
                })
                .catch((err) => {
                  console.log(err);
                });
              this.removePreloader();
            }
          } catch (error) {
            throw error;
          }
        }
      }
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        // await this.updateRow();
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      }

      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    openSidebar(id) {
      this.lead_id = id;
      this.editSidebar = true;
    },
    closeSidebar() {
      this.editSidebar = false;
      this.$refs["refListTable"].refresh();
    },
    // openModalSendSms() {
    //   this.modalSendSms = true;
    // },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    selectedAll() {
      if (this.selectAll == 1) {
        const nameCl = [];
        this.clients.map((dato) => {
          nameCl.push({
            id: dato.account.account_id,
            name: dato.lead_name,
          });
        });
        const value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (!value) {
          this.allClients.push({
            currentPage: this.paginate.currentPage,
            array: nameCl,
          });
        }
      }
      if (this.selectAll == 0) {
        const value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (value) {
          const index = this.allClients.indexOf(value);
          this.allClients.splice(index, 1);
        }
      }
      this.clientsSms = [];
      for (let i = 0; i < this.allClients.length; i++) {
        this.clientsSms = [...this.clientsSms, ...this.allClients[i].array];
      }
    },
    async modalSendEmailOpen(id, name) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [{ id, name }];
        this.modalSendEmail = true;
      }
    },
    async modalSmsOpen(id, name) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [{ id, name }];
        this.modalSendSms = true;
      }
    },
  },
};
</script>

<style></style>
