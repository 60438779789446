var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['refListTable'].refresh()},"onSelectChange":_vm.changeTypePayment},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{staticClass:"ml-1",attrs:{"variant":"success","disabled":_vm.lengthClients},on:{"click":_vm.openModalSendEmail}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"MailIcon"}}),_vm._v("Send Email ")],1)]},proxy:true}])},[_c('b-table',{ref:"refListTable",attrs:{"slot":"table","responsive":"sm","table-class":"text-nowrap","sticky-header":"68vh","show-empty":"","empty-text":"No matching records found","small":"","striped":"","hover":"","fields":_vm.fields,"items":_vm.myProvider,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.isBusy},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"head(selected)",fn:function(){return [_c('b-form-checkbox',{on:{"input":_vm.selectedAll},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})]},proxy:true},{key:"cell(selected)",fn:function(data){return [_c('b-form-group',[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"value":{
              id: data.item.account.account_id,
              name: data.item.lead_name,
            },"name":data.item.account.account_id},model:{value:(_vm.clientsSms),callback:function ($$v) {_vm.clientsSms=$$v},expression:"clientsSms"}})],1)]}},{key:"cell(lead_name)",fn:function(data){return [_c('div',{staticClass:"clickable",class:_vm.isDarkSkin ? 'text-warning' : 'text-primary',staticStyle:{"font-weight":"600"},on:{"click":function($event){return _vm.openSidebar(data.item.lead_id)}}},[_vm._v(" "+_vm._s(data.item.lead_name)+" ")])]}},{key:"cell(account)",fn:function(data){return [_c('router-link',{attrs:{"target":"_blank","to":{
            name: 'specialists-client-dashboard',
            params: { idClient: ("" + (data.item.account.account_id)) },
          }}},[_vm._v(" "+_vm._s(data.item.account.id)+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('status-account',{attrs:{"account":data.item.account}})]}},{key:"cell(charges)",fn:function(data){return [_vm._v(" $ "+_vm._s(data.item.account.charges)+" ")]}},{key:"cell(monthly_amount)",fn:function(data){return [_vm._v(" $ "+_vm._s(data.item.account.monthly_amount)+" ")]}},{key:"cell(last_payment)",fn:function(data){return [_c('div',{staticStyle:{"position":"relative"}},[_c('span',{staticStyle:{"margin-left":"15px"}},[_vm._v(" "+_vm._s(data.item.account.last_payment || "-")+" "),_c('img',{staticStyle:{"position":"absolute","left":"0","top":"50%","transform":"translate(0, -50%)"},attrs:{"src":_vm.assetsImg +
                '/images/icons/lastpayment' +
                (data.item.account.state_lp == 1 ? '-out.ico' : '-in.ico')}})])])]}},{key:"cell(pt)",fn:function(data){return [_c('status-payment',{attrs:{"account":data.item.account}})]}},{key:"cell(advisor_name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.account.advisor_name)+" ")]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.account.created_at))+" ")]}},{key:"cell(services)",fn:function(data){return [_c('actions-table',{attrs:{"options":_vm.serviceOptions,"row-data":data.item},on:{"modalChangeService":function($event){return _vm.openAddService(4, data.item)},"modalAddService":function($event){return _vm.openAddService(3, data.item)}}})]}},{key:"cell(actions)",fn:function(data){return [_c('actions-table',{attrs:{"options":_vm.actionOptions,"row-data":data.item},on:{"modalSendEmailOpen":function($event){return _vm.modalSendEmailOpen(
              data.item.account.account_id,
              data.item.lead_name
            )},"modalSmsOpen":function($event){return _vm.modalSmsOpen(data.item.account.account_id, data.item.lead_name)}}})]}}])})],1),(_vm.modalSendSms)?_c('modal-send-sms',{attrs:{"modal-send-sms":_vm.modalSendSms,"name-leads":_vm.clientsSms[0],"typesms":0},on:{"closeModal":_vm.closeSendSms}}):_vm._e(),(_vm.modalSendEmail)?_c('modal-send-email',{attrs:{"modal-send-email":_vm.modalSendEmail,"name-leads":_vm.clientsSms,"typesms":0},on:{"closeModal":_vm.closeSendSms}}):_vm._e(),(_vm.modalOpenAddService)?_c('modal-add-service',{attrs:{"modal-open-add-service":_vm.modalOpenAddService,"details-client":_vm.detailsClient,"type-modal":_vm.typeModal},on:{"hideModal":_vm.closeModalService,"changeProgram":_vm.openModalProgram}}):_vm._e(),(_vm.modal.programs)?_c(_vm.modalData.programs.programSelected,{tag:"component",attrs:{"type-modal":_vm.modalData.programs.typeModal,"sales-client":_vm.saleClientP,"programs-all":_vm.programsAll,"users-services":_vm.usersServices,"header-s":_vm.headerS},on:{"closeModal":_vm.hideModalProgram,"changeProgram":_vm.openModalProgram}}):_vm._e(),(_vm.editSidebar)?_c('sidebar-edit-lead',{attrs:{"lead_id":_vm.lead_id,"moduleMeta":11},on:{"refresh":_vm.closeSidebar,"close":function($event){_vm.editSidebar = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }